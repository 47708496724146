import React from 'react';
import './App.css';
import osebergimg from './oseberg.jpeg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          scigma.org: Stability Computations and Interactive Graphics for
          Invariant Manifold Analysis
        </p>
        <div>
          <img src={osebergimg} alt="Oseberg Transition" />
        </div>
      </header>
      <div>
        <a href="https://github.com/scigma/scigma">SCIGMA on Github</a>
      </div>

      <div className="Znote">
        Maintained by <a href="https://www.maejohns.com">maejohns</a>
      </div>
    </div>
  );
}

export default App;
